<template>
<div>
    <!-- 通道管理》失败状态列表》失败状态 导入 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>失败状态导入</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            
                <el-form  :model="form_data" label-width="80px" style="margin-bottom: 12px;">

                    <el-form-item label="作用类型">
                        <el-select v-model="form_data.opt_type" size="mini" style="width:100%"> 
                            <el-option label="全局" value="0"></el-option>
                            <el-option label="通道公司" value="1"></el-option>
                            <el-option label="通道" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="通道" v-show="form_data.opt_type == '1' || form_data.opt_type == '2'">
                        <el-select v-model="form_data.channel_id" filterable clearable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="重复处理">
                        <el-select v-model="form_data.repeat_type" size="mini" style="width:100%"> 
                            <el-option label="保留之前的" value="0"></el-option>
                            <el-option label="替换之前的" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                     <el-form-item label="导入文件">
                        <!-- show-file-list:是否显示已上传文件列表  默认值 true
                        multiple:是否支持多选文件 
                        data:上传时附带的额外参数
                        auto-upload	是否在选取文件后立即进行上传
                        with-credentials 是否支持发送 cookie 凭证信息  true:支持
                        before-upload上传之前执行的方法
                        -->
                        <el-upload  ref="uploadFile"  style="display: inline-block;"
                                :action="upload_url" :multiple="false" :limit="1" :auto-upload="false"
                                :with-credentials="true"

                                :data="form_data"

                                :before-upload="upload_before"

                                :on-success="uploadResponseResult"
                                :on-error="uploadResponseResultFail"

                                :file-list="fileList"
                                
                                :on-change="uploadChange"
                                :on-remove="uploadChange"
                                
                                >
                            <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                        
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="文件格式">
                        <el-image style="margin-right:10px" fit="fill" :src="require('@/assets/img/template/state_import_template.png')"
                            :preview-src-list = "previewSrcList"
                         ></el-image>  
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="dataSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            
        </el-row>
    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {
    getItem
} from '../../api/tool.js';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:0,//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取通道列表数据-请求接口获取
                    this.getChannelDataList();
                }, 1);
            }

            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            channelDataList:[],//通道列表数据-请求接口获取

            form_data:{
                opt_type:"0",//作用类型  0:全局 1:通道公司 2:通道
                channel_id:"",//查询条件-通道id
                repeat_type:"1",//重复状态含义操作：0：保留 1：替换
            },

            upload_url:"",//上传url
            upload_param:{},//上传带的额外参数
            fileList:[],//文件列表
            previewSrcList:[require('@/assets/img/template/state_import_template.png')],
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },

        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },


        //数据提交
        dataSubmit(){
            console.log("数据提交 file_num:"+this.fileList.length);
            
            if(this.fileList.length == 0){
                this.$message.error("请先选择文件");
                return ;
            }
            this.$refs.uploadFile.submit();
        },
        upload_before(){
            console.log("上传 之前");
            //上传url
            return new Promise((resolve, reject) => {
                // 拼接上传url
                // 此处动态配置action URL
                this.upload_url = API.getInterfaceUrl()+"/ChannelStateEscapeServlet?param=exportAdd"
                            +"&opt_type="+this.form_data.opt_type
                            +"&channel_id="+this.form_data.channel_id
                            +"&repeat_type="+this.form_data.repeat_type
                            +"&session="+getItem("qxt_session")+"&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
                console.log("上传url:"+this.upload_url);
                // dom上传地址更新完成后，触发上传
                this.$nextTick(() => resolve());
            
            });
        },
        //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            console.log("上传 按钮触发方法");
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            console.log("上传响应结果res");
            console.log(res);
            this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            if(res){
                // res = JSON.parse(res);

                if(res.recode == 0){
                    var duration = 2000;
                    if(API.isEmtry(res.text)){
                        res.text = "恭喜您，失败状态导入成功";
                    }else{
                        duration = 10000;
                    }
                    // this.$message.error(res.text,{duration:duration});
                    this.$message({
                        showClose: true,
                        message: res.text,
                        duration:duration,
                        type: 'warning'
                    });
                }else{
                    this.$message.error(res.text);
                }
            }else{
                 this.$message.error("文件上传失败，请稍后再试哦");
            }

            
        },
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("上传失败响应结果");
            this.$message.error("文件上传失败了，请稍后再试哦");
        },


        

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>