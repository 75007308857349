<template>
    <!-- 通道管理》失败状态列表 -->
    <div id="app_state_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="prop_state_add_i ++" size="mini">
                    增加
                </el-button>
                <el-button type="primary" @click="prop_state_add_import_i ++" size="mini">
                    导入
                </el-button>
            </div>

            <div class="head_top_title">失败状态列表</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id_202205200952" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" label-width="68px">
                    <el-form-item label="状态" >
                        <el-input v-model="form_data.state" placeholder="默认查询全部" size="mini" style="width:88px"></el-input>
                    </el-form-item>

                    <el-form-item label="转义状态">
                        <el-input v-model="form_data.escape_state" placeholder="默认查询全部" size="mini" style="width:88px"></el-input>
                    </el-form-item>

                    <el-form-item label="作用类型">
                        <el-select v-model="form_data.opt_type" size="mini" style="width:95px"> 
                            <el-option label="全部" value="-1"></el-option>
                            <el-option label="全局" value="0"></el-option>
                            <el-option label="通道公司" value="1"></el-option>
                            <el-option label="通道" value="2"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="通道" v-show="form_data.opt_type == '1' || form_data.opt_type == '2'">
                        <el-select v-model="form_data.channel_id" filterable size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>


                </el-form>
                
                
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height-query_condition_height)+'px')}">
                <template v-for="item in tableData">

                    <el-descriptions  title="" :column="1" size="mini" border style="margin-top:10px;">

                        <el-descriptions-item label="类型" label-class-name="el_descriptions_label_class">
                            {{item.show_content}}
                        </el-descriptions-item>

                        <el-descriptions-item label="来源" label-class-name="el_descriptions_label_class">
                            {{item.source}}
                        </el-descriptions-item>

                        <el-descriptions-item label="状态" label-class-name="el_descriptions_label_class">
                            {{item.state}}
                        </el-descriptions-item>

                        <el-descriptions-item label="转义状态" label-class-name="el_descriptions_label_class">
                            {{item.escape_state}}
                        </el-descriptions-item>

                        <el-descriptions-item label="含义" label-class-name="el_descriptions_label_class">
                            <!-- {{item.meaning}} -->
                            <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" label-width="68px">
                                <el-form-item>
                                    <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 6}" v-model="item.meaning"  size="mini"
                                     :style="{width:(content_width-180)+'px'}" @change="item.change_b=false"></el-input>
                                </el-form-item>

                                <el-form-item>
                                    <el-button type="primary" @click="submit_meaning(item)" :disabled="item.change_b" size="mini">提交</el-button>
                                </el-form-item>


                            </el-form>
                        </el-descriptions-item>

                    </el-descriptions>

                    
                </template>
                
            
            </el-row>

            <el-row id="pagination_id_202205200952" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
            

        </el-row>

        
        

    <!-- 失败状态增加组件 -->
    <StateAdd :prop_change_i="prop_state_add_i"
        @result="submit_cha_xun"
      ></StateAdd>

    <!-- 失败状态增加-导入组件 -->
    <StateAddImport :prop_change_i="prop_state_add_import_i"
        @result="submit_cha_xun"
      ></StateAddImport>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import StateAdd from '../channel/state_add.vue';//组件 失败状态增加
import StateAddImport from '../channel/state_add_import.vue';//组件 失败状态增加

//import axios from "axios";
export default {

    components: {
        StateAdd,
        StateAddImport,
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            channelDataList:[],//通道列表数据-请求接口获取

            form_data:{
                state:"",//查询条件-状态 空字符表示全部
                escape_state:"",//查询条件-转义状态 空字符表示全部
                opt_type:"-1",//作用类型 -1:全部 0:全局 1:通道公司 2:通道
                channel_id:"",//查询条件-通道id
                channel_id:"",//查询条件-通道id
            },

            indexPage:1,//分页 第几页
            pageSize:100,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度
            query_condition_height:40,//查询条件的高度



            //失败状态增加
            prop_state_add_i:0,//失败状态增加  组件 弹出框是否可见

            //失败状态增加-导入
            prop_state_add_import_i:0,//失败状态增加 -导入 组件 弹出框是否可见
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.channelDataList = this.$options.data().channelDataList;//单个恢复初始化
        // this.form_data = this.$options.data().form_data;//单个恢复初始化
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
	    // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化

        //重置所有数据
        Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();
         
        //查询
        this.submit_cha_xun();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

        },

        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },

        //查询
        submit_cha_xun(){

            if(this.form_data.opt_type == "1" || this.form_data.opt_type == "2" ){//作用类型 -1:全部 0:全局 1:通道公司 2:通道
                if(API.isEmtry(this.form_data.channel_id)){
                    this.$message.error("请选择通道");
                    return;
                }
            }

            //请求接口
            API.ChannelStateEscapeServlet({
                param: "getList",
                state:this.form_data.state,
                escape_state:this.form_data.escape_state,
                opt_type:this.form_data.opt_type,
                channel_id:this.form_data.channel_id,
               
                indexPage:this.indexPage,
                pageSize:this.pageSize,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    for(var i=0;i<res.list.length;i++){
                        var obj = res.list[i];
                        obj.change_b = true;//
                    }
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;


                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id_202205200952');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id_202205200952');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //状态含义修改提交
        submit_meaning(item){
            if(API.isEmtry(item.meaning)){
                this.$message.error("请先填写含义");
                return;
            }

            //请求接口
            API.ChannelStateEscapeServlet({
                param: "update",
                id:item.id,
                meaning:item.meaning,
            }).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，状态含义修改成功");
                    //更新数据
                    this.submit_cha_xun();
                } 
            });
        },

        //返回
        goBack() {
            API.router_to("/channel_index");
        }
    }
};

</script>

<style scoped>

#app_state_list>>>.el_descriptions_label_class{
    text-align: center;
    width: 80px;
    
}

/*form表单样式调整*/
#app_state_list>>>.el-form-item {
  margin-bottom: 0px;
}

</style>